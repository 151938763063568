import axios from "axios";
import { BASE_URL } from "../../config";
import {
  ADD_ADMIN_REQUEST,
  ADD_ADMIN_REQUEST_SUCCESS,
  ADD_ADMIN_REQUEST_FAILURE,
} from "./addAdminTypes";

const addAdminRequest = () => {
  return {
    type: ADD_ADMIN_REQUEST,
  };
};

const addAdminRequestSuccess = (data) => {
  return {
    type: ADD_ADMIN_REQUEST_SUCCESS,
    payload: data,
  };
};

const addAdminRequestFailure = (error) => {
  return {
    type: ADD_ADMIN_REQUEST_FAILURE,
    payload: error,
  };
};

// Send add admin request
export const sendAddAdminRequest = (
  name,
  mobile,
  new_password,
  confirm_new_password,
  manager,
  team_lead_id,
  employee_type,
  work_mode,
  department,
  shift,
  role,
  company_number,
  team_member,
  role_facing
  // employee_id,

  // date_of_joining,
  // date_of_birth,
  // blood_group,
  // emergency_contact_1,
  // emergency_contact_2
) => {
  return (dispatch) => {
    dispatch(addAdminRequest());
    axios({
      method: "POST",
      url: `${BASE_URL}/admin/user/create-user`,
      data: {
        name,
        mobile,
        new_password,
        confirm_new_password,
        manager_id: Number(manager),
        team_lead_id: team_lead_id === "" ? null : Number(team_lead_id),
        employee_type,
        work_mode,
        department,
        shift,
        role_id: Number(role),
        is_company_number: company_number,
        is_team_member : team_member,
        role_facing
        // employee_id,
        // date_of_joining,
        // date_of_birth,
        // blood_group,
        // emergency_contact_1,
        // emergency_contact_2,
      },
    })
      .then((res) => {
        const data = res.data;
        dispatch(addAdminRequestSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response.data.message;
        dispatch(addAdminRequestFailure(errorMsg));
      });
  };
};
